import { boot } from 'quasar/wrappers';
import { useAuthStore } from 'src/stores/auth';
import { track } from './mixpanel';
import * as Sentry from '@sentry/vue';
import { Capacitor } from '@capacitor/core';

let SHOULD_REGISTER_NOTIFICATIONS = false;
let IS_ANDROID = false;

export default boot(async ({ app, router }) => {
  const q = app.config.globalProperties.$q;
  SHOULD_REGISTER_NOTIFICATIONS = Capacitor.getPlatform() !== 'web';

  if (!SHOULD_REGISTER_NOTIFICATIONS) return;

  IS_ANDROID = q.platform.is.android ?? false;
  const authStore = useAuthStore();

  const { LocalNotifications } = await import('@capacitor/local-notifications');
  const { PushNotifications } = await import('@capacitor/push-notifications');
  const { App } = await import ('@capacitor/app');

  /** Wrapper to navigate when receiving a notification */
  const navigate = async (path: string) => {
    await router.isReady();
    router.push(path);
  };

  // Notify the API of the device token
  await PushNotifications.addListener('registration', async (token) => {
    // Fixes empty dataprice list when registering for push notifications
    // without being logged in. This may happen if the user already accepted
    // push notifications and then logged out.
    if (!authStore.isLoggedIn) return;
    await api.notificationsDeviceTokenCreate({
      token: token.value,
      device_os: q.platform.userAgent
        ? q.platform.is.ios
          ? 'ios'
          : 'android'
        : q.platform.is.desktop
        ? 'web'
        : q.platform.is.ios
        ? 'ios'
        : 'android',
      device_model: q.platform.userAgent,
    });

    track('Registered for push notifications');
  });

  await PushNotifications.addListener('registrationError', (err) => {
    console.error('Registration error: ', err.error);

    Sentry.captureException(err.error);
    track('Failed to register for push notifications');
  });
  if (Capacitor.isNativePlatform()) {
    App.addListener('appStateChange', async (state) => {
      if (state.isActive) {
        if (authStore.isLoggedIn) {
          await registerNotifications(true);
        }
      }
    });
  }

  // Handle foreground push notifications
  await PushNotifications.addListener(
    'pushNotificationReceived',
    async (notification) => {
      track('Received push notification', {
        mode: 'foreground',
        from: 'push',
      });

      // Only rethrow the notification in android because iOS does it automatically
      if (q.platform.is.android) {
        await LocalNotifications.schedule({
          notifications: [
            {
              // Since these notifications are not persistent, we need to
              // generate a random id to avoid duplicates.
              id: Math.floor(Math.random() * 1000),
              title: notification.title as string,
              body: notification.body as string,
              // We need to schedule the notification to be shown in 1 second
              // because otherwise it's unreliable.
              schedule: { at: new Date(Date.now() + 1000) },
              actionTypeId: 'ABASTORES_NOTIFICATION',
              extra: {
                url: notification.data?.url,
              },
            },
          ],
        });
      }
    }
  );

  // Handle background push notifications
  await PushNotifications.addListener(
    'pushNotificationActionPerformed',
    async (notification) => {
      track('Opened push notification', {
        mode: 'background',
        from: 'push',
        data: notification.notification?.data,
      });

      const { url } = notification.notification?.data;

      if (url) {
        await navigate(url);
      }
    }
  );

  // Handle foreground + background local notifications
  await LocalNotifications.addListener(
    'localNotificationActionPerformed',
    (notification) => {
      track('Opened push notification', {
        from: 'local',
        data: notification.notification?.extra,
      });

      const { url } = notification.notification?.extra;
      if (url) {
        navigate(url);
      }
    }
  );

  // In android we can try to register the notifications right away
  if (
    authStore.isLoggedIn &&
    q.platform.is.nativeMobile &&
    q.platform.is.android
  ) {
    await registerNotifications();
  }
});

export const registerNotifications = async (force = false) => {
  // If not native, return.
  if (!SHOULD_REGISTER_NOTIFICATIONS) return;

  // If not forcing (logging in) or not android, return
  if (!force && !Capacitor.isNativePlatform()) {
    return;
  }

  const { PushNotifications } = await import('@capacitor/push-notifications');
  track('Registering push notifications');
  let permStatus = await PushNotifications.checkPermissions();

  if (permStatus.receive === 'prompt') {
    track('System prompting for push notifications');
    permStatus = await PushNotifications.requestPermissions();
  }

  if (permStatus.receive !== 'granted') {
    track('Push notifications denied');
    throw new Error('User denied permissions!');
  }

  await PushNotifications.register();
  track('Push notifications registered');
};

export const getPermissionStatus = async () => {
  if (!SHOULD_REGISTER_NOTIFICATIONS) return;

  const { PushNotifications } = await import('@capacitor/push-notifications');
  return PushNotifications.checkPermissions();
};
