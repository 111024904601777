export interface Theme {
  primary: string;
  'on-primary': string;
  secondary: string;
  'on-secondary': string;
  accent: string;
  'on-accent': string;
  background: string;
  'on-background': string;
  surface: string;
  'on-surface': string;
  surface2: string;
  'on-surface2': string;
  positive: string;
  'on-positive': string;
  chip: string;
  'on-chip': string;
  negative: string;
  'on-negative': string;
}

export const defaultTheme: Theme = {
  primary: 'winter',
  'on-primary': 'white',
  secondary: 'spring',
  'on-secondary': 'black',
  accent: 'summer',
  'on-accent': 'white',
  background: 'white',
  'on-background': 'black',
  surface: 'mist',
  'on-surface': 'winter',
  surface2: 'sea',
  'on-surface2': 'winter',
  positive: 'field',
  'on-positive': 'white',
  chip: 'sky',
  'on-chip': 'winter',
  negative: 'summer',
  'on-negative': 'white',
};

export const greenTheme: Theme = {
  primary: 'field',
  'on-primary': 'white',
  secondary: 'dryDirt',
  'on-secondary': 'black',
  accent: 'summer',
  'on-accent': 'white',
  background: 'white',
  'on-background': 'black',
  surface: 'pear',
  'on-surface': 'field',
  surface2: 'aloe',
  'on-surface2': 'field',
  positive: 'field',
  'on-positive': 'white',
  chip: 'spring',
  'on-chip': 'moss',
  negative: 'summer',
  'on-negative': 'white',
};

export const whiteTheme: Theme = {
  primary: 'winter',
  'on-primary': 'white',
  secondary: 'winter',
  'on-secondary': 'white',
  accent: 'winter',
  'on-accent': 'white',
  background: 'winter',
  'on-background': 'white',
  surface: 'white',
  'on-surface': 'winter',
  surface2: 'sea',
  'on-surface2': 'winter',
  positive: 'field',
  'on-positive': 'white',
  chip: 'sky',
  'on-chip': 'winter',
  negative: 'summer',
  'on-negative': 'white',
};
