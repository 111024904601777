<template>
  <q-dialog>
    <q-card>
      <q-card-section class="pb-2">
        <h2 class="text-night my-0 text-18px text-center w-full block lh-6">
          {{ title }}
        </h2>
      </q-card-section>
      <q-card-section class="flex flex-col items-center pt-2">
        <!-- eslint-disable-next-line vue/no-v-html -- this variable should be defined externally by us -->
        <p class="text-carbon text-center" v-html="text"></p>
        <q-btn
          v-close-popup
          :label="buttonLabel"
          color="primary"
          :icon="buttonIcon"
          class="w-full"
          :to="typeof buttonTo === 'string' ? buttonTo : buttonTo($route)"
          @click="clicked"
        />
        <i v-if="footer" class="text-12px text-center text-carbon mt-2">
          {{ footer }}
        </i>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import { track } from 'src/boot/mixpanel';
import { RouteLocationNormalizedLoaded } from 'vue-router';

export interface Props {
  title: string;
  text: string;
  buttonLabel: string;
  buttonIcon: string;
  buttonTo: string | ((route: RouteLocationNormalizedLoaded) => string);
  footer?: string;
  dialogName?: string | undefined;
}
const props = defineProps<Props>();

function clicked() {
  if (props.dialogName) {
    track('Clicked in dialog', {
      dialog: props.dialogName,
      button: props.buttonLabel,
    });
  }
}

onMounted(() => {
  if (props.dialogName) {
    track('Dialog shown', {
      dialog: props.dialogName,
    });
  }
});
</script>
