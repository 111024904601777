import AwaitLock from 'await-lock';

export async function lockConcurrency<T>(
  lock: AwaitLock,
  procedure: (...args: unknown[]) => T | Promise<T>
) {
  // if (lock.acquired) {
  //   // Will wait for the lock to be over
  //   await lock.tryAcquire();
  // } else {
  // // Lock the function
  await lock.acquireAsync();
  try {
    return await procedure();
  } finally {
    lock.release();
  }
  // }
}
