import AwaitLock from 'await-lock';
import { defineStore } from 'pinia';
import { api, V2DataPrice, KindEnum, DataPrice } from 'src/api/client';
import { lockConcurrency } from 'src/util/lockConcurrency';
import { z } from 'zod';
import { ReactionVoted } from './reactions';

export const useMarketDataStore = defineStore('marketData', () => {
  const url = new URL(window.location.href);
  const filterSchema = z
    .object({
      province: z.string().nullable(),
      provinces: z.array(z.string()).nullable(),
      kind: KindEnum.nullable(),
      products: z.array(z.string()).nullable(),
    })
    .partial();
  const filter = ref(
    filterSchema.parse({
      province: url.searchParams.get('province') ?? null,
      provinces: url.searchParams.getAll('provinces') || null,
      kind: url.searchParams.get('kind') ?? null,
      products: url.searchParams.getAll('products') || null,
    })
  );
  const items = ref<z.infer<typeof V2DataPrice>[]>([]);
  const reactions = ref<Record<string, {like: number, dislike: number, good: number, bad: number, voted: ReactionVoted | undefined, voted_id?: number}>>({})

  const page = ref<number | undefined>(1);
  const initialLoading = ref(true);
  const fromIframe = ref(false);
  const lock = new AwaitLock();
  const adverts = computedAsync(
    () =>
      api.advertisingAdvertisementList({
        queries: {
          placement: 'DP',
          province: Array.isArray(filter.value.province)
            ? filter.value.province
            : [filter.value.province ?? ''],
        },
      }),
    []
  );

  const fetchNextPage = async () => {
    const filterWas = JSON.stringify(filter.value);
    return lockConcurrency(lock, async () => {
      if (filterWas !== JSON.stringify(filter.value)) {
        initialLoading.value = true;
        items.value = [];
        page.value = 1;
      }
      if (!page.value) {
        initialLoading.value = false;
        return false;
      }

      const headers: Record<string, string> = {};
      if (fromIframe.value) {
        headers['X-Origin'] = 'FRAME';
      }

      const { next, results } = await api.marketdataDatapriceList({
        queries: {
          province: filter.value.province ?? undefined,
          provinces: filter.value.provinces ?? undefined,
          kind: filter.value.kind ?? undefined,
          products: filter.value.products ?? undefined,
          page: page.value,
        },
        headers,
      });
      if (results?.length) {
        items.value = [...items.value, ...results];
        const response = await api.reactionsDataPriceRetrieve({
          queries: {
            id: results.map((result) => result.id).join(',')
          }
        })
        reactions.value = {...reactions.value,...response}
      }
      if (next) {
        page.value += 1;
      } else {
        page.value = undefined;
      }
      initialLoading.value = false;
      return page.value !== undefined;
    }).then((res) => res ?? page.value !== undefined);
  };

  const refresh = async () => {
    initialLoading.value = true;
    items.value = [];
    page.value = 1;
    await fetchNextPage();
  };

  const setReaction = (data_price: string, reaction_type: ReactionVoted | undefined, new_reaction: any) => {
    reactions.value = {
      ...reactions.value,
      [data_price]: {
        voted: reaction_type,
        like: new_reaction.like,
        dislike: new_reaction.dislike,
        good: new_reaction.good,
        bad: new_reaction.bad,
        voted_id: new_reaction.voted_id ?? reactions.value[data_price]?.voted_id,
      }
    }
  }

  const currentPage = computed(() => page.value);

  watch(filter, refresh);

  return {
    filter,
    items,
    currentPage,
    fetchNextPage,
    initialLoading,
    refresh,
    fromIframe,
    adverts,
    reactions,
    setReaction
  };
});
