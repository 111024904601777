import { boot } from 'quasar/wrappers';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { useAuthStore } from 'src/stores/auth';

const VITE_GTM_ID = import.meta.env.VITE_GTM_ID;
const VITE_GTAG_ID = import.meta.env.VITE_GTAG_ID;
const VITE_META_PIXEL_ID = import.meta.env.VITE_META_PIXEL_ID;

export default boot(() => {
  const head = document.getElementsByTagName('head')[0];
  if (!head) throw new Error('No head tag found');

  if (VITE_GTM_ID) {
    console.info('Loading Google Tag Manager');
    const layer = 'dataLayer';

    // Inits event layer
    window[layer] = window[layer] || [];

    // Adds starting event
    window[layer].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });

    const gtmScript = document.createElement('script');
    const dataLayerParameter = layer !== 'dataLayer' ? `&l=${layer}` : '';
    gtmScript.async = true;
    gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${VITE_GTM_ID}${dataLayerParameter}`;
    gtmScript.onload = () => {
      console.info('Google Tag Manager loaded');
    };
    head.appendChild(gtmScript);
  }

  if (VITE_GTAG_ID) {
    console.info('Loading Google Analytics');
    const gtagScript = document.createElement('script');
    gtagScript.async = true;
    gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${VITE_GTAG_ID}`;
    gtagScript.onload = () => {
      console.info('Google Analytics loaded');
      window.dataLayer = window.dataLayer || [];
      function gtag(...args: unknown[]) {
        window.dataLayer?.push(args);
      }
      gtag('js', new Date());
      gtag('config', VITE_GTAG_ID);
    };
    head.appendChild(gtagScript);
  }

  if (VITE_META_PIXEL_ID) {
    console.info('Loading Facebook Pixel');

    if (window.fbq) return;

    // Create fbq function
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- fbq is a special function
    const metaFbq: any = (window.fbq = (...args: unknown[]) => {
      metaFbq.callMethod
        ? metaFbq.callMethod(metaFbq, ...args)
        : metaFbq.queue.push(args);
    });
    if (!window._fbq) window._fbq = metaFbq;

    // Define fbq properties
    metaFbq.push = metaFbq;
    metaFbq.loaded = true;
    metaFbq.version = '2.0';
    metaFbq.queue = [];

    // Load fbq script
    const metaScript = document.createElement('script');
    metaScript.async = true;
    metaScript.src = 'https://connect.facebook.net/en_US/fbevents.js';
    metaScript.onload = () => {
      console.info('Facebook Pixel loaded');
    };
    head.appendChild(metaScript);

    // Track page view
    window.fbq('init', VITE_META_PIXEL_ID);
    window.fbq('track', 'PageView');
  }
});

export async function identifyUserAnalytics() {
  const { user } = useAuthStore();
  if (!user) return;
  await FirebaseAnalytics.setUserId({
    userId: user.id.toString(),
  });
  await FirebaseAnalytics.setUserProperty({
    name: 'email',
    value: user.email as string,
  });
}

export async function trackEventAnalytics(
  event: string,
  properties?: Record<string, unknown>
) {
  await FirebaseAnalytics.logEvent({
    name: event,
    params: properties ?? {},
  });
}

declare global {
  interface Window {
    dataLayer?: unknown[] | undefined;
    fbq?:
      | ((
          event:
            | 'track'
            | 'init'
            | 'trackCustom'
            | 'trackSingle'
            | 'trackSingleCustom',
          eventName:
            | 'AddPaymentInfo'
            | 'AddToCart'
            | 'AddToWishlist'
            | 'CompleteRegistration'
            | 'Contact'
            | 'CustomizeProduct'
            | 'Donate'
            | 'FindLocation'
            | 'InitiateCheckout'
            | 'Lead'
            | 'PageView'
            | 'Purchase'
            | 'Schedule'
            | 'Search'
            | 'StartTrial'
            | 'SubmitApplication'
            | 'Subscribe'
            | 'ViewContent',
          params?: object
        ) => void)
      | undefined;
    _fbq?: unknown | undefined;
  }
}
